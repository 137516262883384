<template v-if="formActive">
    <FormItem id="country_id" :label="$t('overview.country')" v-model="terminalholiday.country_id" type="livesearch" sizeItem="4" :options="countries" :content="terminalholiday.country ? terminalholiday.country.name : ''"/>
    <FormItem id="start_date" :label="$t('overview.start_date')" type="datepicker" v-model="terminalholiday.start_date" :required="true" />
    <FormItem id="end_date" :label="$t('overview.end_date')" type="datepicker" v-model="terminalholiday.end_date" :required="true" />
    <FormItem id="description" :label="$t('overview.description')" v-model="terminalholiday.description" :required="true" />
</template>
                    


<script>
    import dataStore from '@/store/data';

    export default {
        name: 'TerminalholidayForm',
        props: ['itemData', 'countries'],
        data() {
            return {
                terminalholiday: {},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.terminalholiday = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.terminalholiday;
            },
            setData(data) {
                this.terminalholiday = data
            },
            reset() {
                this.terminalholiday = {
                    'description': null,
                    'start_date': null,
                    'end_date': null,
                    'country_id': null
                },
                
                this.loading = false
                this.invalid = {}
            },
        }
    }
</script>