<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'description'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :filterOptions="filterOptions"
    >
        <template #columns>
            <Column field="country.name" :header="$t('overview.country')" style="width: 15%"></Column>
            <Column field="start_date" :header="$t('overview.start_date')" style="width: 10%">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.start_date, 'shortDate')}}
                </template>
            </Column>
            <Column field="end_date" :header="$t('overview.end_date')" style="width: 10%">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.end_date, 'shortDate')}}
                </template>
            </Column>
            <Column field="description" :header="$t('overview.description')" ></Column>
        </template>
        

        <template #form>
            <TerminalHolidayForm ref="ItemForm" :itemData="item" :countries="countries"/>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import TerminalHolidayForm from '@/components/terminalholiday/TerminalHolidayForm.vue';
import terminalHolidayService from '@/services/TerminalHolidayService';
import countryService from '@/services/CountryService';

export default {
    components: {
        Overview,
        Column,
        TerminalHolidayForm
    },
    data() {
        return {
            item: {},
            itemService: terminalHolidayService,
            itemFormRef: null,
            selectFields: [
                "id",
                "description",
                "start_date",
                "end_date",
                "country_id",
            ],
            countries: []
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        countryService.index().then(response => {
            this.countries = response.data;
        }).catch(error => {
            console.log(error);
        });
    }
}
</script>