import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/terminal-holiday'

class TerminalHolidayService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new TerminalHolidayService()